import { ArrayHelper } from "../../../../../../../../utilities/contracts/array-helper";
import { BooleanHelper } from "../../../../../../../../utilities/contracts/boolean-helper";
import { StringHelper } from "../../../../../../../../utilities/contracts/string-helper";
import { RiskDxDiagnosis } from "./risk-dx-diagnosis.model";
import { DxType, RiskDxIcd } from "./risk-dx-icd.model";

export class RiskDx {
  admin?: RiskDxIcd[];
  eve?: RiskDxIcd[];
  coder?: RiskDxIcd[];
  chaseId?: number;
  isReadOnly: boolean;
  masterEveEncounterId: number;
  isEveOnlyCardsValidationRequired?: boolean;
  isReviewAllAdminDOSEnabled?: boolean;

  constructor(options: Partial<RiskDx> = {}) {
    this.chaseId = options.chaseId;
    this.admin = ArrayHelper.clean(options.admin, []);
    this.eve = ArrayHelper.clean(options.eve, []);
    this.coder = ArrayHelper.clean(options.coder, []);
    this.isEveOnlyCardsValidationRequired = BooleanHelper.tryGet(options.isEveOnlyCardsValidationRequired, true);
    this.isReadOnly = options.isReadOnly;
    this.masterEveEncounterId = options.masterEveEncounterId;
    this.admin.forEach(group => group.dxType = DxType.ADMIN);
    this.eve.forEach(group => group.dxType = DxType.EVE);
    this.isReviewAllAdminDOSEnabled = BooleanHelper.tryGet(options.isReviewAllAdminDOSEnabled, true);
  }

  get dxCount(): number {
    return this.admin?.length + this.eve?.length  + this.coder?.length ?? 0;
  }

  get isReadyToSubmit(): boolean {
    return (!this.isEveOnlyCardsValidationRequired || this.eve.every(eve => BooleanHelper.isAvailable(eve.isValidated)))
      && this.admin.every(admin => BooleanHelper.isAvailable(admin.isValidated))
      && this.coder.every(coder => BooleanHelper.isAvailable(coder.isValidated));
  }

  get isV22ReadyToSubmit(): boolean {
    const hccCardWithEveDiagnosesOnly = this.eve.filter(eve =>
      eve.diagnoses.every(diagnosis => diagnosis.isEve)
    );
    const eveOnlyHccIdentifiersToSkip = new Set(hccCardWithEveDiagnosesOnly.map(eve => `${eve.modelId}-${eve.hccId}`));

    const isCardValidated = (status: string) =>
      status === "Validated" || status === "Invalidated";

    const areAdminDiagnosesReviewed = (diagnoses: RiskDxDiagnosis[]) =>
      diagnoses
        .filter(diagnosis => diagnosis.isAdmin)
        .every(({ diagnosisStatus, vrcCode }) =>
          diagnosisStatus === "A" ||
          diagnosisStatus === "V" ||
          (diagnosisStatus === "D" && StringHelper.isAvailable(vrcCode))
        );

    const areEveDiagnosesReviewed = (diagnoses: RiskDxDiagnosis[]) =>
      diagnoses
        .filter(diagnosis => diagnosis.isEve)
        .every(({ diagnosisStatus, vrcCode }) =>
          diagnosisStatus === "A" ||
          diagnosisStatus === "V" ||
          (diagnosisStatus === "D" && StringHelper.isAvailable(vrcCode))
        );

    // Main conditional logic based on flags
    if (this.isReviewAllAdminDOSEnabled && this.isEveOnlyCardsValidationRequired) {
      return this.eve.every(eve =>
        isCardValidated(eve.hccCardValidationStatus) &&
        areAdminDiagnosesReviewed(eve.diagnoses) &&
        areEveDiagnosesReviewed(eve.diagnoses)
      );
    } else if (this.isReviewAllAdminDOSEnabled && !this.isEveOnlyCardsValidationRequired) {
      return this.eve.every(eve => {
        // Skip if 'eve' card exists in the skip list
        if (eveOnlyHccIdentifiersToSkip.has(`${eve.modelId}-${eve.hccId}`)) {
          return true;
        }
        return areAdminDiagnosesReviewed(eve.diagnoses);
      });
    } else if (!this.isReviewAllAdminDOSEnabled && this.isEveOnlyCardsValidationRequired) {
      return this.eve.every(eve => {
        if (eveOnlyHccIdentifiersToSkip.has(`${eve.modelId}-${eve.hccId}`)) {
          return isCardValidated(eve.hccCardValidationStatus) &&
            areEveDiagnosesReviewed(eve.diagnoses);
        }
        return true;

      });
    } else {
      return true;
    }
  }
}
