import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { ContactMethodType } from "../../../cover-letter-template/contact-method-type.enum";

export const mapAddressDetailInfoEdit = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "AddressDetailInfoEdit")
    .forMember("retrievalLocationId", o => o.retrievalLocationId)
    .forMember("address1", o => o.address1)
    .forMember("address2", o => o.address2)
    .forMember("addressCity", o => o.addressCity)
    .forMember("addressState", o => o.addressState)
    .forMember("addressZip", o => o.addressZip)
    .forMember("phone", o => o.phone)
    .forMember("email", o => o.email)
    .forMember("faxNumber", o => o.faxNumber)
    .forMember("contactName", o => o.contactName)
    .forMember("nextCallDate", o => o.nextCallDate)
    .forMember("nextCallDateTime", o => o.nextCallDateTime)
    .forMember("groupName", o => o.groupName)
    .forMember("vendorId", o => o.vendorId)
    .forMember("weeklyContactMethodTypeId", o => o.weeklyContactMethodTypeId)
    .forMember("primaryContactPhone", o => o.primaryContactPhone)
    .forMember("isContactVerified", o => o.isContactVerified);
};

export class AddressDetailInfoEdit {
  retrievalLocationId: number;
  address1: string;
  address2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  emrSystem?: string;
  phone: string;
  email: string;
  faxNumber: string;
  contactName: string;
  nextCallDate: string;
  nextCallDateTime: string;
  groupName: string;
  vendorId: number;
  weeklyContactMethodTypeId?: ContactMethodType;
  retrievalTypeID?: number;
  primaryContactPhone?: string;
  isContactVerified?: boolean;
  contactId?: number;

  constructor(options: Partial<AddressDetailInfoEdit> = {}) {
    this.retrievalLocationId = options.retrievalLocationId;
    this.address1 = options.address1 || "";
    this.address2 = options.address2 || "";
    this.addressCity = options.addressCity || "";
    this.addressState = options.addressState || "";
    this.addressZip = options.addressZip || "";
    this.emrSystem = options.emrSystem || "";
    this.email = options.email || "";
    this.phone = options.phone || "";
    this.faxNumber = options.faxNumber || "";
    this.contactName = options.contactName || "";
    this.nextCallDate = options.nextCallDate || "";
    this.nextCallDateTime = options.nextCallDateTime;
    this.groupName = options.groupName;
    this.vendorId = options.vendorId;
    this.weeklyContactMethodTypeId = options.weeklyContactMethodTypeId;
    this.retrievalTypeID = options.retrievalTypeID;
    this.primaryContactPhone = options.primaryContactPhone || "";
    this.isContactVerified = options.isContactVerified || false;
    this.contactId = options.contactId || null;
  }
}
