export class ProviderReceiptResponse {
  readonly masterDocumentSourceID: number;
  readonly uploadBatchCode: string;
  readonly originalDocumentName: string;
  readonly fileSize: string;
  readonly fileCreateDate: string;
  readonly fileCreateTime: string;
  readonly documentUploadStatus: string;
  constructor(options: {
    masterDocumentSourceID: number;
    uploadBatchCode: string;
    originalDocumentName: string;
    fileSize: string;
    fileCreateDate: string;
    fileCreateTime: string;
    documentUploadStatus: string;
  }) {
    this.masterDocumentSourceID = options.masterDocumentSourceID;
    this.uploadBatchCode = options.uploadBatchCode;
    this.originalDocumentName = options.originalDocumentName;
    this.fileSize = options.fileSize;
    this.fileCreateDate = options.fileCreateDate;
    this.fileCreateTime = options.fileCreateTime;
    this.documentUploadStatus = options.documentUploadStatus;
  }
}

