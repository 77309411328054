import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../core/storage/local.service";
import { UserService } from "../../../../../../../core/user/user.service";
import { PendStatus } from "../../../../../../../shared/pend/pend-status.enum";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { ChaseSearchRequest } from "../../../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { EntityError } from "../../../../../../api/member-validation/entity-error.model";
import { WorkflowStatusDb } from "../../../../../../api/workflow/workflow-status-db.enum";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { CodingReviewMode } from "../../../../../project/project-config/coding-review-mode.enum";
import { ChartService } from "../../../../chase-detail/chase-detail-chart/chart.service";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../chase-detail/chase-detail-state.service";
import { DocumentViewerSessionService } from "../../../document-viewer-session.service";
import { RiskDx } from "../dx-models/response/risk-dx.model";
import { RiskDxService } from "../risk-dx.service";

@Component({
  selector: "app-risk-dx-submit",
  templateUrl: "./dx-submit.component.html",
  styleUrls: ["./dx-submit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskDxSubmitComponent implements OnInit {
  private sink = new SubSink();
  private chaseDetailState: ChaseDetailState;
  private allDxValidated: boolean;
  private isReadOnly: boolean;
  private user: UserToken;
  private validationErrors: EntityError[];
  private isCoderRequiredToReviewAdminEncounter: boolean;
  private isEveOnlyCardsValidationRequired: boolean;
  private openChaseAndUserInfo = "openChaseAndUserInfo";
  private get workFlowStatus(): string {
    return WorkflowStatusDb[this.chaseDetailState?.workflowStatus];
  }

  get hasUnresolvedPend(): boolean {
    return this.chaseDetailState.hasPend &&
      (this.chaseDetailState.pendStatusId === PendStatus.New || this.chaseDetailState.pendStatusId === PendStatus.InProgress);
  }
  get isCodingReviewModeDX(): boolean {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === CodingReviewMode.DIAGNOSIS;
  }
  get isChaseStatusCompleted() {
    return this.chaseDetailState?.workflowStatus === WorkflowStatusDb.Completed || this.chaseDetailState?.workflowStatus === WorkflowStatusDb.Delivery;
  }

  get disabled(): boolean {
    return (!this.allDxValidated || this.isReadOnly || this.isChaseStatusCompleted);
  }
  get isOverread(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread;
  }

  get isOverread2(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread2;
  }

  get errors(): EntityError[] {
    return this.validationErrors;
  }

  constructor(
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly riskDxService: RiskDxService,
    private readonly chaseSearchService: ChaseSearchService,
    private readonly chartService: ChartService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly messagingService: MessagingService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly changeDetector: ChangeDetectorRef,
    private localService: LocalService) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUserToken();
    this.sink.add(
      this.riskDxService.data$
        .pipe(
          filter(state => NumberHelper.isAvailable(state.chaseId)),
          map(state => {
            if (this.isCodingReviewModeDX) {
              return this.setSubmissionEnabled(state);
            } else {
              return this.setV22SubmissionEnabled(state);
            }
          })
        )
        .subscribe(),

      this.chaseDetailStateService.state
        .pipe(
          map(state => {
          this.chaseDetailState = state;
          this.isCoderRequiredToReviewAdminEncounter = state?.projectConfiguration?.reviewAdminEncountersAndDiagnoses === "1";
          this.isEveOnlyCardsValidationRequired = state?.projectConfiguration?.eveOnlyCardsValidationRequired === "1";
          })
          )
        .subscribe()
    );
  }

  submit(): void {
    this.chartService.submit2(this.chaseDetailState.chaseId, this.workFlowStatus)
      .pipe(
        map(entityErrors => {
          if (ArrayHelper.isAvailable(entityErrors)) {
            this.setEntityErrors(entityErrors);
          } else {
            this.submissionSuccess();
          }
        }))
      .subscribe();
  }
  private submissionSuccess(): void {
    this.messagingService.showToast("Submission Succeeded!", SeverityType.SUCCESS);
    this.removeOpenChaseInfo();
    this.submitNavigation();
  }


  private removeOpenChaseInfo(): void {
    const localChaseUserInfo =  this.localService.get(this.openChaseAndUserInfo, null);
    if (localChaseUserInfo) {
      this.localService.delete(this.openChaseAndUserInfo);
    }
  }

  private setSubmissionEnabled(riskState: RiskDx): void {
    riskState.isEveOnlyCardsValidationRequired = this.isEveOnlyCardsValidationRequired;
    this.isReadOnly = riskState.isReadOnly;
    this.allDxValidated = riskState.isReadyToSubmit;
    if (!ArrayHelper.isAvailable(riskState.admin) && !ArrayHelper.isAvailable(riskState.eve) && !ArrayHelper.isAvailable(riskState.coder) && this.allDxValidated) {
      this.allDxValidated = !this.isCoderRequiredToReviewAdminEncounter ;
    }
    this.changeDetector.markForCheck();
  }
  private setV22SubmissionEnabled(riskState: RiskDx): void {
    riskState.isEveOnlyCardsValidationRequired = this.isEveOnlyCardsValidationRequired;
    riskState.isReviewAllAdminDOSEnabled = this.isCoderRequiredToReviewAdminEncounter;
    this.isReadOnly = riskState.isReadOnly;
    this.allDxValidated = riskState.isV22ReadyToSubmit;
    this.changeDetector.markForCheck();
  }
  private submitNavigation(): void {
    this.getQueueOrNextChase();
  }
  get functionalRoleId(): number {
    switch (this.chaseDetailState.workflowStatus) {
      case WorkflowStatusDb.Abstraction:
        return FunctionalRole.ABSTRACTOR;
      case WorkflowStatusDb.Overread:
        return FunctionalRole.OVERREAD;
      case WorkflowStatusDb.Overread2:
        return FunctionalRole.CLIENTOVERREAD;
      default:
        return 0;
    }
  }

  private getQueueOrNextChase(): void {
    const request = new ChaseSearchRequest(
      null, null, null, this.functionalRoleId, null, this.user.userId, null, null, null, null, null, null, 0, 1, null, null, null, null, null, this.chaseDetailState.reportingStatusName
    );
    this.chaseSearchService.chaseSearch(request).subscribe(data => {
      if (ArrayHelper.isAvailable(data)) {
        this.navigateToChase(data[0].chaseId, true);
      } else {
        this.router.navigate(["dashboard", "clinical"]);
      }
    });
  }

  private navigateToChase(chaseId: number, reload: boolean = false): void {
    const path = "/members/chase";

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}/${chaseId}`], { state: { reload } });
    }
    );
  }

  private setEntityErrors(entityErrors: EntityError[]): void {
    this.validationErrors = entityErrors;
    this.changeDetector.markForCheck();
  }
}
