import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { BooleanHelper } from "../../../../../../utilities/contracts/boolean-helper";
import { WorkflowStatus } from "../../../../../api/workflow/worklow-status.enum";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";
import { VrcService } from "./diagnosis/vrc.service";
import { RiskState } from "./risk-state.model";
import { RiskService } from "./risk.service";

@Component({
  selector: "member-risk",
  templateUrl: "./risk.component.html",
  styleUrls: ["./risk.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private vrcsRemovedFromTheDropdown = ["01", "21", "61", "99", "106"];
  private measureYear = 0;
  private projectType = "";
  private workflowStatus = "";
  private chaseId = 0;
  private hasVrcs: boolean;
  private hasEncounterTypes: boolean;

  constructor(
    private readonly chaseDetailState: ChaseDetailStateService,
    private readonly riskService: RiskService,
    private readonly vrcService: VrcService
  ) { }

  ngOnInit() {
    this.subscribeToState();
    this.getVrcs();
    this.getEncounterTypes();
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }


  private subscribeToState(): void {
    this.sink.add(
      combineLatest([
        this.riskService.data,
        this.chaseDetailState.state,
      ]).subscribe(([riskState, chaseDetailState]: any) => {
        this.setStateProperties(riskState, chaseDetailState);
      })
    );
  }

  private setStateProperties(state: RiskState, chaseDetailState: ChaseDetailState): void {
    this.hasVrcs = BooleanHelper.tryGet(state.hasVrcs, false);
    this.hasEncounterTypes = BooleanHelper.tryGet(state.hasEncounterTypes, false);

    if (chaseDetailState != null && chaseDetailState.hasProject) {
      this.measureYear = chaseDetailState.measureYear;
      this.projectType = chaseDetailState.project.projectTypeName.toUpperCase();
      this.workflowStatus = WorkflowStatus[chaseDetailState.workflowStatus];
      this.chaseId = chaseDetailState.chaseId;
    }
  }

  private getVrcs(): void {
    if (!this.measureYear || !this.projectType || !this.workflowStatus || !this.chaseId) {
      setTimeout(() => this.getVrcs(), 50);
      return;
    }

    if (!this.hasVrcs) {
      this.vrcService.getVrcs(this.measureYear, this.projectType, this.workflowStatus, this.chaseId)
        .pipe(map(this.filterVrcs.bind(this)))
        .subscribe(this.setStateVrcs.bind(this));
    }
  }

  private filterVrcs(vrcs: SelectableInput[]): SelectableInput[] {
    return vrcs.filter(this.removeVrcs.bind(this));
  }

  private removeVrcs(vrc: SelectableInput): boolean {
    return !this.vrcsRemovedFromTheDropdown.includes(vrc.value.toString());
  }

  private setStateVrcs(vrcs: SelectableInput[]): void {
    this.riskService.setData({ vrcs });
  }

  private getEncounterTypes(): void {
    if (!this.hasEncounterTypes) {
      this.riskService.getEncounterTypes().subscribe(this.setStateEncounterTypes.bind(this));
    }
  }

  private setStateEncounterTypes(encounterTypes: SelectableInput[]): void {
    this.riskService.setData({ encounterTypes });
  }
}
