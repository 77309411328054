import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { LocalService } from "../../core/storage/local.service";
import { FormService } from "../../dynamic-forms/form.service";
import { TextboxType } from "../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../dynamic-forms/inputs/textbox/textbox.model";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  models: any[] = [];
  identityMicroserviceUrl = "";
  isPrivacyPolicyVisible = false;
  refresh = false;
  private readonly localStorageKeysToClear: string[] = [
    "active_session",
    "refresh",
    "ng2Idle.main.expiry",
    "ng2Idle.main.idling",
];

  constructor(
    private readonly authService: AuthService,
    private readonly formService: FormService,
    private local: LocalService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.local.delete("ssoClientId");
    this.local.delete("ssoClientIdValidation");
    this.refresh = this.local.get("refresh", false);
    if (this.refresh) {
      this.localStorageKeysToClear.forEach(key => localStorage.removeItem(key));
      window.location.reload();
    } else {
      this.getIdentityMicroserviceUrl();
      this.createForm();
    }
  }


  login(): void {
    const username = this.form.get("username").value;
    const password = this.form.get("password").value;
    this.authService.login(username, password);
  }

  showPrivacyPolicy(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.isPrivacyPolicyVisible = true;
  }

  removeWhiteSpace(model): void {
   if (model.controlType === "textbox") {
    const value = this.form.get(model.key).value;
    this.form.get(model.key).setValue(value.trim());
    this.changeDetector.markForCheck();
   }
  }

  private getIdentityMicroserviceUrl(): void {
    this.authService.getMicroserviceUrl().subscribe(result => {
      const requestUrl = "resetpassword/request";
      this.identityMicroserviceUrl = result + requestUrl;
      this.changeDetector.markForCheck();
    });
  }

  private createForm(): void {
    this.models = [
      new Textbox({
        key: "username",
        label: "Username",
        autocomplete: "username",
        placeholder: "Username",
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Username is required.",
        },
      }),
      new Textbox({
        key: "password",
        type: TextboxType.PASSWORD,
        label: "Password",
        autocomplete: "current-password",
        placeholder: "Password",
        validators: [
          Validators.required,
        ],
        errorMessages: {
          required: "Password is required.",
        },
      }),
    ];
    this.form = this.formService.createFormGroup(this.models);
  }

  trackByIndex(index, item) {
    return index;
  }
}
