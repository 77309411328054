<div class="empty-header" *ngIf="showSubmitHeader"></div>
<app-chase-detail-v2-chart-viewer-header
*ngIf="!isSubmitTabSelected"
[currentTabSelected]="tabSelected"
[selectedThumbnailData]="allSelectedThumbnailItems"
[resetMoveBackDocumentToggleSwitch]="resetMoveBackToggleButtonSwitch"
[expandedView]="expandedView"
[expandedLabelView]="expandedLabelView"
(selectedFilters)="selectedFilter($event)"
(onSelectionthumbnailEvent)="selectedthumbnailEvent($event)"
[documentPageIds]="documentPageIds"
[allEvidencesDocumentPageIds]="allEvidencesDocumentPageIds"
[isSplitScreenMainTab]="isSplitScreenMainTab"
></app-chase-detail-v2-chart-viewer-header>
<div class="document-page-viewer">
  <app-chase-document-page-thumbnails *ngIf="!showExpandedView" [ngStyle]="{display: isDuplicateWindowOpen ?'none':''}"
  [ngClass]="{'smallView': showChartThumbnails}" [isViewChanged]="isViewChanged"
    (toggleThumbnailViewClicked)="toggleThumbnailView($event)" class="document-page-viewer__thumbnails"
    [chaseId]="chaseId" [currentChartPageNumber]="currentPageNumber" [isMaximized]="isMaximized" [documentPageIds]="documentPageIds"></app-chase-document-page-thumbnails>

  <app-chase-document-page-thumbnail-expand *ngIf="showExpandedView && showChartThumbnails && !isSplitScreenMainTab"
    (toggleThumbnailViewClicked)="toggleThumbnailView($event)"
    (selectedDocumentThumbnailItems)="selectedDocumentThumbnailItems($event)"
    (resetMoveBackToggleSwitch)="resetToggleSwitch($event)"
    (expandedView)="isExpandedView($event)"
    [ngClass]="{'expandedView': showExpandedView}"
    class="document-page-viewer__thumbnails"
    [chaseId]="chaseId"
    [addressId]="addressId"
    [projectId]="projectId"
    [isMaximized]="isMaximized"
    [isSelectedThumbnailEventOption] ="onSelectionthumbnailEvent"
    (updateTotalPages)="updateTotalPages($event)"
    [currentChartPageNumber]="currentPageNumber" [currentTabSelected]="tabSelected" [documentPageIds]="documentPageIds"></app-chase-document-page-thumbnail-expand>

  <app-chase-document-page-thumbnail-expand-labeled *ngIf="isSplitScreenMainTab"
    [ngClass]="{'expandedView': isSplitScreenMainTab}"
    class="document-page-viewer__thumbnails"
    [chaseId]="chaseId"
    [addressId]="addressId"
    [projectId]="projectId"
    [isMaximized]="isMaximized"
    (expandedLabelView)="isExpandedLabelView($event)"
    [isSplitScreenMainTab]="isSplitScreenMainTab"
    [currentChartPageNumber]="currentPageNumber" [currentTabSelected]="tabSelected" [documentPageIds]="documentPageIds"></app-chase-document-page-thumbnail-expand-labeled>
  <div class="document-page-viewer__chart" [ngClass]="{'expandedView': showExpandedView}">
    <div class="command-bar" id="commandBar">
      <div class="command-bar__item command-bar__item--extraSpacer" *ngIf="isSplitScreenEnabled">
        <app-button *ngIf="isPopOutBtnEnabled"
                    class="command-bar__item command-bar__item--icon command-bar__item--split-icon" icon="external-link-alt"
                    styleType="far" (onClick)="openSplitScreenURL()">
        </app-button>
      </div>
      <div #ocrSearchContainer *ngIf="isOcrSearchEnabled" class="command-bar__item">
        <div #ocrSearchInput class="command-bar__item command-bar__item--ocr-input" (click)="preventHide($event)">
          <form-factory [formGroup]="searchPhraseForm" [model]="searchPhraseInput"
          (keydown.enter)="fetchOcrMatches(ocrSearchResults)"  (onFocus)="onOcrSearchPhraseFocus(ocrSearchInput, ocrSearchResults)">
          </form-factory>
        </div>
        <app-button *ngIf="showOcrSearchIcon"
                    class="command-bar__item command-bar__item--left command-bar__item--ocr-search-icon"
                    classes="transparent x-small" icon="play fa-search" [disabled]="!isOcrFocus"
                    (onClick)="fetchOcrMatches(ocrSearchResults)">
        </app-button>
        <app-button *ngIf="!showOcrSearchIcon"
                    class="command-bar__item command-bar__item--left command-bar__item--ocr-search-icon"
                    classes="transparent x-small" icon="times-circle" (onClick)="resetOcrSearchInput()">
        </app-button>
        <div #ocrSearchResults hidden class="ocr-results" (click)="preventHide($event)">
          <div *ngIf="isOcrSearchInProgress; else elseBlock" class="ocr-results__search">
            search in progress...
          </div>
          <ng-template #elseBlock>
            <div *ngIf="!isOcrSearchInProgress && !hasOcrSearchResults" class="ocr-results__search">
              No Matches Found.
            </div>
            <div *ngIf="hasOcrSearchResults" class="ocr-results__description">
              <div class="command-bar__item">
                <b>Page Results</b>: {{ ocrDocumentResultsSummary }}
              </div>
              <div class="command-bar__item command-bar__item--traversing-btns command-bar__item--float-right">
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                            classes="transparent x-small" icon="play fa-flip-horizontal" (onClick)="gotoPrevOCRMatchPage()">
                </app-button>
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--right"
                            classes="transparent x-small" icon="play" (onClick)="gotoNextOCRMatchPage()">
                </app-button>
              </div>
            </div>

            <div *ngIf="hasOcrSearchResults" class="ocr-results__summary">
              <div class="command-bar__item">
                <b>Results per Page</b>: {{ ocrDocumentPageWordsSummary }}
              </div>
              <div class="command-bar__item  command-bar__item--traversing-btns command-bar__item--float-right">
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                            classes="transparent x-small" icon="play fa-flip-horizontal" (onClick)="gotoPrevOCRMatchPageWord()">
                </app-button>
                <app-button class="command-bar__item command-bar__item--icon command-bar__item--right"
                            classes="transparent x-small" icon="play" (onClick)="gotoNextOCRMatchPageWord()">
                </app-button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="command-bar__item command-bar__item--spacer"></div>
      <app-button *ngIf="!isReadOnly && annotationsEnabled" [ngClass]="highlightBtnClass"
                  class="command-bar__item command-bar__item--icon command-bar__item--highlight-icon" icon="highlighter"
                  styleType="far" [disabled]="isMaximized" (onClick)="toggleAnnotation()">
      </app-button>
      <div class="command-bar__item command-bar__item--btn-spacer"></div>

      <div class="command-bar__item command-bar__item--float-right">
        <div *ngIf="isDataEntryWorkflow" class="command-bar__item" title="Click Optimize to generate a higher resolution document.">
          <app-button [disabled]="!enableOptimizeImageButton" class="command-bar__item command-bar__item--optimize" text="Optimize"
                      (onClick)="optimizeChart()">
          </app-button>
        </div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--left" icon="plus"
                    (onClick)="zoomIn()">
        </app-button>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--right" icon="minus"
                    (onClick)="zoomOut()">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button *ngIf="!isReadOnly" class="command-bar__item command-bar__item--icon command-bar__item--left"
                    icon="undo-alt" [disabled]="disableChartFunctions" (onClick)="rotate('left')">
        </app-button>
        <app-button *ngIf="!isReadOnly" class="command-bar__item command-bar__item--icon command-bar__item--right"
                    icon="redo-alt" [disabled]="disableChartFunctions" (onClick)="rotate('right')">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <div class="command-bar__item">
          <form-factory class="command-bar__item command-bar__item--page-number-input"
                        [formGroup]="currentPageNumberForm" [model]="currentPageNumberInput" (onChange)="onGotoPage($event)">
          </form-factory>
          <span>&nbsp;of&nbsp;{{ totalPages }}</span>
        </div>
        <div class="command-bar__item command-bar__item--spacer"></div>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--left"
                    icon="play fa-flip-horizontal" [disabled]="!isCurrentPageLoaded" (onClick)="prevPage()">
        </app-button>
        <app-button class="command-bar__item command-bar__item--icon command-bar__item--right" icon="play"
                    [disabled]="!isCurrentPageLoaded" (onClick)="nextPage()">
        </app-button>
        <div class="command-bar__item command-bar__item--spacer"></div>
      </div>
    </div>
    <div id="pageViewer" (mouseover)="onMouseOver()">
      <cdk-virtual-scroll-viewport #viewport class="document"  [ngClass]="{ 'max': isMaximized, 'expand': this.menuService.showHeader }" [itemSize]="itemSizeHeight" [minBufferPx]="minBuffer"
                                    [maxBufferPx]="maxBuffer"
                                    (document:keydown.ArrowRight)="nextPage()" (document:keydown.ArrowLeft)="prevPage()">
        <div class="document__page" [ngClass]="{'scroll-item': isWindowOpen}" [style.height.px]="itemSizeHeight"
        *cdkVirtualFor="let page of pages$; templateCacheSize: 0; trackBy: trackByIndex;">
          <app-document-page-annotations *ngIf="annotationToggle && hasPageAndSource(page)"
                                          class="document__page__annotations" [isReadOnly]="isReadOnly" [status]="status" [isDiagnosisSelected]="isDiagnosisOpen"
                                          [documentPageId]="page.documentPageId" [pageAnnotations]="getPageAnnotationsV2(page.documentPageId)" [chasePageAnnotations]="getPageAnnotationsForChase(page.documentPageId)"
                                          [canvasHeight]="canvasHeight" [canvasWidth]="canvasWidth" [imageWidth]="imageWidthSize" [zoomPercentage]="zoomPercentage"
                                          [zoomStyle]="zoomStyle" [margin]="margin" [itemSize] ="itemSize" (onChange)="saveDocumentAnnotations($event, null)">
          </app-document-page-annotations>
          <app-document-hover-highlights *ngIf="annotationToggle && hasPageAndSource(page)"
            [hoverAnnotations]="getPageAnnotationsV2(page.documentPageId)"
            [chasePageAnnotations]="getPageAnnotationsForChase(page.documentPageId)"
            [canvasHeight]="canvasHeight"
            [canvasWidth]="canvasWidth"
            [chaseId]="chaseId"
            [itemSize]="itemSize"
            [imageWidth]="imageWidthSize"
            [zoomPercentage]="zoomPercentage"
            [documentPageId]="page.documentPageId"
            [pageNumber]="page.pageNumber"
            [zoomStyle]="zoomStyle"
            [margin]="margin"
            [isReadOnly]="isReadOnly"
            (highlightToDelete)="highlightToDelete($event)">
          </app-document-hover-highlights>
          <ng-container *ngIf="hasOcrWordsAndDocumentMetaData(page)">
            <div *ngFor="let word of getOcrWords(page); trackBy: trackByIndex;" >
            <canvas #canvas [ngStyle]="getWordStyles(word)"></canvas>
            </div>
          </ng-container>
          <div class="text-center">
            <img cdkDrag *ngIf="hasPageAndSource(page)" class="document__page__image" [src]="getSource(page, page.source)"
                  [ngStyle]="zoomStyle" id="documentsImg"/>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>