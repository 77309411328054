import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-coder-productivity-dw",
  templateUrl: "./coder-productivity-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoderProductivityDWComponent {

  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.CODER_PRODUCTIVITY_REPORT_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
