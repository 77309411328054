import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../../../core/storage/local.service";
import { SaveGroup } from "../../../../../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { FormService } from "../../../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { DynamicInput } from "../../../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { ProviderDropdown } from "../../../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { SelectableInput } from "../../../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TagSearchMultiselect } from "../../../../../../../../../dynamic-forms/inputs/tag-search-multiselect/tag-search-multiselect.model";
import { TextboxType } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../../../dynamic-forms/validators/date-between.validator";
import { GenericAsyncValidator } from "../../../../../../../../../dynamic-forms/validators/generic.async-validator";
import { ArrayHelper } from "../../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../../../../api/member-validation/entity-type.enum";
import { WorkflowStatusDb } from "../../../../../../../../api/workflow/workflow-status-db.enum";
import { CHART_PAGE_NUMBER, DIAGNOSIS_CODE, EXEMPT_FROM_SCORING, FROM_DATE, PROVIDER_ID, THRU_DATE, VALIDATION_REASON_CODES } from "../../../../../../chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../../../../../chase-detail/chase-detail-chart/chart.service";
import { Diagnosis } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { IcdService } from "../../../../../../chase-detail/chase-detail-chart/risk/diagnosis/icd.service";
import { RiskEntity } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskHelper } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { ChaseDetailV2ChartRiskService } from "../../../risk.service";
import { DiagnosisService } from "../diagnosis.service";

@Component({
  selector: "member-risk-diagnosis-v2-coder",
  templateUrl: "./coder.component.html",
  styleUrls: ["./coder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosisV2CoderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chaseDetailState: ChaseDetailState;
  @Input() coderDiagnoses: Diagnosis[];
  @Input() riskState: RiskState;
  @Output() showVRCWarningForNoMatch = new EventEmitter<boolean>();

  activeState: boolean[] = [false];
  form: FormGroup;
  isConfirmDeleteVisible = false;
  isVRCWarningVisible = false;
  saveGroup: SaveGroup;
  selected: { diagnosis: Diagnosis; riskEntity: RiskEntity };
  vrcsExclusion = ["39", "107"];
  private sink = new SubSink();
  private icdCodeInput: Autocomplete;
  private pageNumberInput: Textbox;
  private providerInput: ProviderDropdown;
  private startDateInput: Textbox;
  private vrcInput: TagSearchMultiselect;
  private serviceProviderRequired = "";
  private selectedEncounterId: string;
  private saving: boolean;
  private saveEnabled: boolean;
  private deletingDiagnosis: boolean;
  newDiagnosisIndex = [];
  exemptInput: Checkbox;
  isOverread = false;
  currentDiagnosisIndex: number;
  vrcRules: string[];
  vrcForDiagnoses: string[] = [];
  selectedProvider: string;
  vrcMatched = true;
  isVRCMatching = false;
  newDiagnosis = false;
  encounterProvider: string;
  encounterStartDate: string;
  private overreadHighlightArray: any[] = [];
  private toggledOverread = false;
  private localStorageIsWindowOpen = "isWindowOpen";
  private localStoragePageNumber = "pageNumber";

  constructor(
    private readonly automapper: AutomapperService,
    private readonly changeDetector: ChangeDetectorRef,
    private chartService: ChartService,
    private readonly diagnosisService: DiagnosisService,
    private readonly formService: FormService,
    private genericAsyncValidator: GenericAsyncValidator,
    private readonly icdService: IcdService,
    private messagingService: MessagingService,
    private readonly riskService: RiskService,
    private authService: AuthService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private localService: LocalService) { }

  get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  get isvrcRequired(): boolean {
    return this.chaseDetailState.isVrcRequired;
  }

  get isEnabled(): boolean {
    {
      const selectedEncounterIsSaved = NumberHelper.isGreaterThan(this.riskState.selectedEncounter.entityId, 0);
      return this.riskState.isEnabled && this.riskState.isSelectedEncounterValid && selectedEncounterIsSaved;
    }
  }

  get totalDiagnoses(): number {
    return this.coderDiagnoses.filter(d => NumberHelper.isAvailable(d.diagnosisId)).length;
  }

  get isEmployeeRole(): boolean {
    return (!this.authService.user.isAdminRole || !this.authService.user.isManagerRole || !this.authService.user.isLeadRole) && this.authService.user.isEmployeeRole;
  }

  get isSaveEnabled(): boolean {
    return this.saveEnabled;
  }

  ngOnInit(): void {
    this.isOverread = this.chaseDetailState.isOverread && !this.isEmployeeRole;
    this.vrcRules = this.chaseDetailState.diagnosisValidationReasonCodes;
    this.initializeForm();

    this.sink.add(
      this.chaseDetailStateService.isEncounterSelected$
        .pipe(filter(encounter => this.selectedEncounterId !== encounter.id))
        .subscribe(encounter => {
          this.collapseAllTabs();
          this.selectedEncounterId = encounter.id;
        }),
      this.chaseDetailStateService.isOverreadToggle$.subscribe(res => {
        if (this.selectedEncounterId && res) {
          this.toggledHighlights(res);
          this.changeDetector.markForCheck();
        }
      }),
      this.diagnosisService.newDiagnosis$.subscribe(newDiagnosisId => {
        if (newDiagnosisId) {
          this.newDiagnosis = true;
          setTimeout(() => {
            this.onNewDiagnosisCreated(newDiagnosisId);
          });
        }
      }),
      this.riskService.dxCardProvider$
        .pipe(
          filter((newProvider: any) => !ObjectHelper.isEmpty(newProvider) && ArrayHelper.isAvailable(newProvider.providers)),
          map(this.newProvidedAdded.bind(this)))
        .subscribe(),
      this.chaseDetailV2ChartRiskService.previousUrl$
        .pipe(filter((previousUrl: string) => previousUrl?.includes("submit")))
        .subscribe(_ => this.isDiagnosisSelected())
    );

    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coderDiagnoses?.firstChange) {
      return;
    }
    this.copyEncounterValues();

    if (JSON.stringify(changes.coderDiagnoses.currentValue) !== JSON.stringify(changes.coderDiagnoses.previousValue) && !this.toggledOverread) {
      this.coderDiagnoses = changes.coderDiagnoses.currentValue;

      if (this.selected) {
        this.selected.diagnosis = this.currentDiagnosisIndex ? this.coderDiagnoses[this.currentDiagnosisIndex] : this.coderDiagnoses[0];
      }

      if (this.deletingDiagnosis) {
        this.deletingDiagnosis = false;
      } else {
        this.selectDiagnosisByIndex(this.newDiagnosis ? this.coderDiagnoses.length - 1 : this.currentDiagnosisIndex);
      }
    }
    if (!NumberHelper.isAvailable(this.riskState.selectedDiagnosis.parentEntityId)) {
      this.riskState.selectedDiagnosis.parentEntityId = this.riskState.selectedEncounter.entityId;
    }
  }

  copyEncounterValues(): void {

    const selectedEncounterAttributes = this.riskService.data.value.selectedEncounter;

    const providerAttribute = selectedEncounterAttributes.attributes
      .find(attribute => attribute.attributeCode === PROVIDER_ID.attributeCode);
    this.encounterProvider = providerAttribute?.value.toString();
    this.encounterStartDate = RiskHelper.getAttribute("StartDate", selectedEncounterAttributes)?.value?.toString();

    this.form.get("Diagnosis").get("Provider").setValue(this.encounterProvider);
    this.form.get("Diagnosis").get("startDate").setValue(this.encounterStartDate);
  }

  saveDiagnosis(): void {
    const dynamicControl: any = this.saveGroup.controls;
    this.save(dynamicControl);
  }

  private validateInputs(): boolean {
    return this.validateVrcCode() && this.form.valid && this.validateProvider();
  }

  private tryEnablingSaveButton(): void {
     if (!this.saving && this.validateInputs()) {
      this.saveEnabled = true;
      this.changeDetector.markForCheck();
    }
  }

  private disableSaveButton(saving = true): void {
    this.saving = saving;
    this.saveEnabled = false;
    this.changeDetector.markForCheck();
  }

  handleKeyUp(): void {
    this.tryEnablingSaveButton();
  }

  handleChange(event: any, isCheckbox = false): void {
    switch (event.type) {
      case "save":
      case "notify":
        this.tryEnablingSaveButton();
        break;
      default:
        break;
    }

    if (isCheckbox) {
      const selectedExemptDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses
        .find(diagnosis => diagnosis.id === this.selected.diagnosis.id);

      const currentExemptAttribute = selectedExemptDiagnosis.attributes
        .find(attribute => attribute.attributeId === EXEMPT_FROM_SCORING.attributeId);

      let newExemptAttribute;
      if (currentExemptAttribute) {
        newExemptAttribute = { ...currentExemptAttribute, value: event ? "1" : "0" };
      } else {
        newExemptAttribute = {
          chaseId: selectedExemptDiagnosis.chaseId,
          entityId: selectedExemptDiagnosis.entityId,
          entityTypeId: EntityType.DIAGNOSIS,
          parentEntityId: selectedExemptDiagnosis.parentEntityId,
          ...EXEMPT_FROM_SCORING,
          value: event ? "1" : "0",
        };
      }
      this.saveAttributes([newExemptAttribute] as DynamicEntityAttribute[]);
    }
  }

  onTabOpen(event: any) {
    this.selectDiagnosisByIndex(event.index);
  }

  private selectDiagnosisByIndex(diagnosisIndex: number): void {
    this.newDiagnosis = false;
    this.currentDiagnosisIndex = diagnosisIndex;
    const diagnosis: Diagnosis = this.coderDiagnoses[diagnosisIndex];
    this.updateSelectedDiagnosis(diagnosis?.id);
    setTimeout(() => {
      this.selectDiagnosis(diagnosis);
      this.getEncounterDetailsForHeader(this.currentDiagnosisIndex);
      this.getOverreadHighlights();
      this.disableSaveButton(false);
    });
  }

  toggledHighlights(status: string) {
    this.toggledOverread = true;
    this.activeState[this.riskState?.selectedDiagnosisIndex] = true;
    const currentDiagnosis = this.coderDiagnoses[this.riskState?.selectedDiagnosisIndex];
    const currentTab = document.getElementById(`coder-${currentDiagnosis.id}`);
    const pageNumber = currentTab.getElementsByTagName("input")[0];
    const vrcEle = currentTab.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab.getElementsByTagName("p-multiselect")[1];

    if (status === WorkflowStatusDb[WorkflowStatusDb.Abstraction]) {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      this.getOverreadHighlights();
      vrcEle.children[0].classList.remove("control__input--overread");
      omissionEle.children[0].classList.remove("control__input--overread");
      pageNumber.classList.remove("control__input--overread");
    } else {
      const selectedDiagnosis = this.riskService.data.value.selectedEncounter.diagnoses[this.riskState.selectedDiagnosisIndex];
      const index = selectedDiagnosis.attributes.findIndex(x => x.attributeCode === "ChartPageNumber");
      this.form.controls.Diagnosis.get("pageNumber").setValue(selectedDiagnosis.attributes[index].value);
      const selected = this.overreadHighlightArray.filter(x => x.diagnosisIndex === this.currentDiagnosisIndex)[0];
      if (selected?.vrc) {
        vrcEle.children[0].classList.add("control__input--overread");
      }
      if (selected?.page) {
        pageNumber.classList.add("control__input--overread");
      }
    }
    setTimeout(() => {
      this.toggledOverread = false;
    },         1500);
  }

  getOverreadHighlights() {
    const index = this.overreadHighlightArray.findIndex(x => x.diagnosisIndex === this.currentDiagnosisIndex);
    if (index >= 0) {
      this.overreadHighlightArray.splice(index, 1);
    }
    const currentDiagnosis = this.coderDiagnoses[this.currentDiagnosisIndex];
    const currentTab = document.getElementById(`coder-${currentDiagnosis?.id}`);
    const pageNumber = currentTab?.getElementsByTagName("input")[0];
    const vrcEle = currentTab?.getElementsByTagName("p-multiselect")[0];
    const omissionEle = currentTab?.getElementsByTagName("p-multiselect")[1];

    const vrcHighlights = vrcEle?.children[0]?.classList?.value?.includes("control__input--overread");
    const pageHighlights = pageNumber?.classList?.value?.includes("control__input--overread");
    const selectedDiagnosisHighlightedProperties = {
      diagnosisIndex: this.currentDiagnosisIndex,
      vrc: vrcHighlights,
      page: pageHighlights,
    };
    this.overreadHighlightArray.push(selectedDiagnosisHighlightedProperties);

  }

  getBadgeStatus(diagnosis: Diagnosis, index: number) {

    if (!diagnosis.diagnosisId) {
      return "add_dx";
    }

    if (this.activeState[index]) {
      return "reviewing";
    }

    if (!StringHelper.isAvailable(diagnosis?.actualVrcs)) {
      return "review";
    }

    if (diagnosis.isValid) {
      return "validated";
    }

    return "notValid";
  }

  getEncounterDetailsForHeader(index: number) {

    const diagnosis: Diagnosis = this.coderDiagnoses[index];
    const diagnosisId = diagnosis?.id;
    const diagnosisData: DiseaseDetail = this.diagnosisService.getDiseaseDetail(diagnosisId);
    const controlvalue = this.providerInput.options.find(option => option.value === diagnosis?.providerSourceAliasId);
    this.selectedProvider = controlvalue?.extra?.providerName;
    const diagnosisDetail = {
      icdCode: (diagnosisData?.icdCode || diagnosis?.text),
      diseaseName: diagnosisData?.diseaseName,
      modelDisplayName: diagnosisData?.modelDisplayName,
      dos: diagnosis?.endDate || diagnosis?.dosFrom,
      isDiagnosSelected: true,
      providerName: this.selectedProvider,
    };

    this.chaseDetailStateService.encounterDetails.next(diagnosisDetail);
    this.chaseDetailStateService.isFilterButtonSelected.next(false);
  }

  showDeleteDiagnosisModal(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.isEnabled) {
      this.isConfirmDeleteVisible = true;
    }
  }

  deleteDiagnosis() {
    this.deletingDiagnosis = true;
    this.isConfirmDeleteVisible = false;
    this.collapseAllTabs();
    const diagnosisDeleting = this.riskState.selectedDiagnosis;

    if (NumberHelper.isGreaterThan(diagnosisDeleting.entityId, 0)) {
      this.chartService.delete(diagnosisDeleting.attributes)
        .subscribe(
          () => this.riskService.deleteDiagnosis(this.riskState.selectedDiagnosisIndex),
          () => this.messagingService.showMessage("Try to delete the diagnosis again.", SeverityType.ERROR)
        );
    } else {
      this.riskService.deleteDiagnosis(this.riskState.selectedDiagnosisIndex);
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  private initializeForm(): void {
    this.exemptInput = new Checkbox({
      key: `exemptInput`,
      label: "Exempt",
    });

    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      classOverride: "control-page-number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });

    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date From",
      classOverride: "control-start-date",
      type: TextboxType.TEXT,
      dataType: "date",
      validators: [
        Validators.required,
      ],
      asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
      errorMessages: {
        required: "Date From is required",
      },
    });

    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      placeholder: "Select...",
    });

    this.vrcInput = new TagSearchMultiselect({
      key: "vrc",
      label: "VRC",
      placeholder: "Select...",
      maxSelectedLabels: 150,
    });


    this.icdCodeInput = new Autocomplete({
      key: "Icd",
      label: "ICD",
      searchMinimum: 2,
      showDropdownArrow: false,
      serverFilter: (query, setOptionsFn) =>
        this.sink.add(
          this.icdService.getIcds(this.riskState.chaseId, query, this.startDateInput.value as string).subscribe(setOptionsFn)
        ),
      validators: [Validators.required],
      errorMessages: {
        required: "ICD Code is required",
        invalidicd: "Invalid ICD Code",
      },
    });

    this.saveGroup = new SaveGroup({
      key: "Diagnosis",
      isHideClearButton: true,
      controls: [
        this.pageNumberInput,
        this.startDateInput,
        this.providerInput,
        this.icdCodeInput,
        this.vrcInput,
      ],
    });

    this.pageNumberInput.parent = this.saveGroup;
    this.startDateInput.parent = this.saveGroup;
    this.providerInput.parent = this.saveGroup;
    this.vrcInput.parent = this.saveGroup;
    this.icdCodeInput.parent = this.saveGroup;

    this.form = this.formService.createFormGroup([this.saveGroup, this.exemptInput]);
  }

  private selectDiagnosis(diagnosis: Diagnosis): void {
    const riskEntity: RiskEntity = this.riskState.selectedDiagnosis;
    this.selected = { diagnosis, riskEntity };
    this.updateFormWithProjectConfiguration(this.chaseDetailState);
    if (this.selected.riskEntity.entityTypeId) {
      this.fillData();
    }
    if (NumberHelper.isAvailable(diagnosis?.pageNumber)) {
      const index = this.newDiagnosisIndex.findIndex(indx => indx === this.currentDiagnosisIndex);
      if (index === -1) {
        this.diagnosisService.updateDataEntryPageNumber(diagnosis.pageNumber, diagnosis.diagnosisValidationResult);
      }
      if (NumberHelper.isGreaterThan(index, -1)) {
        this.newDiagnosisIndex.splice(index, 1);
      }
      this.diagnosisService.toggleDiagnosisSelected(true);

      if (this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
        if (diagnosis?.pageNumber !== null) {
          this.localService.put(this.localStoragePageNumber, diagnosis?.pageNumber);
        }
      }
    }
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "0";
    }
  }

  private fillData() {
    const chaseId = this.riskState.chaseId;
    const workflowStatusName = this.riskState.workflowStatusName.toLowerCase();
    const diagnosisId = this.selected.diagnosis?.diagnosisId;
    const entityTypeId = this.selected.riskEntity.entityTypeId;
    const encounterId = this.selected.diagnosis?.encounterId;
    const selectedEncounterIsSaved = NumberHelper.isGreaterThan(this.riskState.selectedEncounter.entityId, 0);
    const disabled = !this.riskState.isEnabled || !this.riskState.isSelectedEncounterValid && !selectedEncounterIsSaved;

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.exemptInput,
      ATTRIBUTE_DATA: EXEMPT_FROM_SCORING,
    });
    this.form.controls.exemptInput.setValue(this.selected.diagnosis?.exempt);

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.isBotSource(),
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.pageNumberInput,
      ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
    });

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.isBotSource(),
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.startDateInput,
      ATTRIBUTE_DATA: FROM_DATE,
      value: this.encounterStartDate,
    });

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled: disabled || this.isBotSource(),
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.icdCodeInput,
      ATTRIBUTE_DATA: DIAGNOSIS_CODE,
    });

    this.sink.add(
      this.icdService
        .getIcds(chaseId, this.icdCodeInput.value, this.startDateInput.value as string)
        .subscribe(
          result => {
            this.icdCodeInput.options = result;
            const firstOption = result.length === 1 ? result[0] : null;
            if (firstOption != null && firstOption.value === this.icdCodeInput.value) {
              const icdCodeControl = this.form.get(this.icdCodeInput.getMasterKey());
              icdCodeControl.setValue(firstOption);
            }
            this.changeDetector.markForCheck();
          }
        )
    );

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.providerInput,
      ATTRIBUTE_DATA: PROVIDER_ID,
      value: this.encounterProvider,
    });

    const attribute = {
      ...RiskHelper.getAttribute(DIAGNOSIS_CODE.attributeCode, this.selected.riskEntity),
      ...DIAGNOSIS_CODE,
      chaseId,
      entityId: diagnosisId,
      entityTypeId,
      parentEntityId: encounterId,
    };
    this.providerInput.options = this.riskState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    this.providerInput.disabled = disabled;
    const control = this.form.get(this.providerInput.getMasterKey());
    this.providerInput.disabled ? control.disable() : control.enable();

    this.setupAttribute({
      chaseId,
      diagnosisId,
      encounterId,
      entityTypeId,
      disabled,
      workflowStatusName,
      singleEntity: this.selected.riskEntity,
      input: this.vrcInput,
      hidden: !this.isvrcRequired,
      ATTRIBUTE_DATA: VALIDATION_REASON_CODES,
    });

    if (!ArrayHelper.isAvailable(this.vrcInput?.options)) {
      this.vrcInput.options = this.riskState.vrcs.filter(x => x.extra.isActive !== false && !this.vrcsExclusion.includes(x.value.toString()));
    }
    if (StringHelper.isAvailable(this.selected.diagnosis?.vrcs)) {
      const selectedValues = this.selected.diagnosis.vrcs.split(",");
      this.vrcInput.selectedOptions = this.selectedOptions(selectedValues, this.vrcInput);
    }

  }

  private selectedOptions(selectedValues: any, multiselect: TagSearchMultiselect): SelectableInput[] {

    const selectedOptions = selectedValues.reduce(
      (acc, value) => {
        const found = multiselect.options?.find(option => option.value === value);
        if (found != null) {
          acc.push(found);
        }
        return acc;
      },
      []);

    return selectedOptions;
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    diagnosisId,
    encounterId,
    entityTypeId,
    disabled,
    workflowStatusName,
    hidden = false,
    value,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId: diagnosisId,
      entityTypeId,
      parentEntityId: encounterId,
    };

    this.updateAttributeBasedOnProjectConfiguration(attribute);

    input.hidden = hidden;
    input.saveInfo = attribute;
    input.value = ATTRIBUTE_DATA === PROVIDER_ID || ATTRIBUTE_DATA === FROM_DATE ? StringHelper.isAvailable(attribute.value) ? attribute.value : value :
      ATTRIBUTE_DATA === DIAGNOSIS_CODE ? attribute.value?.replace(".", "") : attribute.value;
    input.disabled = disabled || attribute.isAdmin;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(input.value);
    input.disabled ? control.disable() : control.enable();
  }

  private updateAttributeBasedOnProjectConfiguration(attribute: DynamicEntityAttribute): DynamicEntityAttribute {
    if (!this.isvrcRequired && attribute.attributeCode === VALIDATION_REASON_CODES.attributeCode) {
      attribute.value = "01";
    }
    return attribute;
  }

  private updateSelectedDiagnosis(diagnosisId: string) {
    const selectedDiagnosisIndex = this.riskService.data.value.selectedEncounter.diagnoses.findIndex(diagnosis => diagnosis.id === diagnosisId);
    this.riskService.setData({ selectedDiagnosisIndex });
  }

  private save(controls: DynamicInput[]): void {
    if (!this.validateInputs()) {
      return;
    }

    this.disableSaveButton();

    const attributes = controls
      .filter(this.isAttributeAvailable.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];

    this.extendAttributes(attributes);
    this.saveAttributes(attributes);
  }

  private validateVrcCode(): boolean {
    if (!this.isvrcRequired) {
      return true;
    }


    const vrcInputValue = this.form.controls.Diagnosis.get("vrc").value;
    return this.diagnosisService.checkIfVRC108Selected(vrcInputValue);

  }

  validateProvider(): boolean {
    const providerControl = this.form.controls.Diagnosis.get(this.providerInput.key);
    if (!this.isServiceProviderRequired) {
      return true;
    } else {
      if (providerControl.value == null || !StringHelper.isAvailable(providerControl.value)) {
        providerControl.setErrors({ required: true });
        return false;
      } else {
        providerControl.setErrors(null);
        return true;
      }
    }
  }

  private extendAttributes(attributes: DynamicEntityAttribute[]): void {
    const endDateAttribute = RiskHelper.getAttribute(THRU_DATE.attributeCode, this.selected.riskEntity);
    const startDateAttribute = RiskHelper.getAttribute(FROM_DATE.attributeCode, this.selected.riskEntity);
    const startDate = attributes.find(attr => attr.attributeCode === FROM_DATE.attributeCode).value;

    if (!endDateAttribute.value || startDateAttribute.value !== startDate) {
      endDateAttribute.value = startDate;
    }

    attributes.push(endDateAttribute);
    attributes.push(this.setExemptAttribute());
  }

  private isAttributeAvailable(attribute: DynamicInput): boolean {
    return attribute != null
      && attribute.saveInfo != null
      && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0)
      && !attribute.disabled;
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    const formInput = this.form.get(control.getMasterKey());
    if (saveAttribute.attributeId === VALIDATION_REASON_CODES.attributeId) {
      const selectedValues = Array.isArray(formInput.value) ? (ArrayHelper.isAvailable(formInput.value) ? formInput.value?.map(x => x.value).toString() : undefined) : formInput.value;
      const sortedSelectedValues = selectedValues?.split(",").sort(StringHelper.compareNumbersForSortASC).join(",");
      saveAttribute.value = sortedSelectedValues;
    } else {
      saveAttribute.value = saveAttribute.attributeId === DIAGNOSIS_CODE.attributeId ? formInput.value.value : formInput.value;
    }

    return saveAttribute;
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]) {
    this.sink.add(
      this.riskService
        .save(attributes, true)
        .pipe(
          finalize(() => this.saving = false)
        )
        .subscribe(
          newAttributes => {
            this.diagnosisService.removeDiseaseDetail(this.riskState.selectedDiagnosis.id);
            this.riskState.selectedDiagnosis.codingModels = []; // Force to load coding models from attributes
            this.riskService.setDiagnosisAttributes(this.riskService.data.value.selectedDiagnosisIndex, newAttributes);
            this.isVRCMatching = this.diagnosisService.checkIfVRCMatches(this.riskState, this.vrcRules);
            this.showVRCWarningForNoMatch.emit(!this.isVRCMatching);
            if (!NumberHelper.isAvailable(this.currentDiagnosisIndex)) {
              this.currentDiagnosisIndex = 0;
            }
            this.getEncounterDetailsForHeader(this.currentDiagnosisIndex);
            this.messagingService.showToast("Coder Dx Updated!", SeverityType.SUCCESS);
            this.collapseAllTabs();
            this.diagnosisService.toggleDiagnosisSelected(false);
            this.changeDetector.markForCheck();
          },
          (e: HttpErrorResponse) => {
            this.form.setErrors({ saveError: e.error });
            this.messagingService.showToast("Coder Dx Not Updated", SeverityType.WARN);
          }
        )
    );
  }

  private collapseAllTabs(): void {
    for (let i = 0; i < this.coderDiagnoses.length; i++) {
      this.activeState[i] = false;
    }
    this.changeDetector.markForCheck();
  }

  private onNewDiagnosisCreated(diagnosisId: string): void {
    this.newDiagnosisIndex.push(this.currentDiagnosisIndex);
    const index = this.coderDiagnoses.length - 1;
    this.collapseAllTabs();
    this.selectDiagnosis(this.coderDiagnoses[index]);
    this.activeState[index] = true;
    this.scrollIntoView(diagnosisId);
  }

  private isDiagnosisSelected(): void {
    if (this.riskState.hasSelectedDiagnosisIndex) {
      const diagnosisId = this.riskState.selectedDiagnosis.id;
      const index = this.coderDiagnoses.findIndex(d => d.id === diagnosisId);

      if (NumberHelper.isGreaterThan(index, 0, true)) {
        this.selectDiagnosis(this.coderDiagnoses[index]);
        this.activeState[index] = true;
        this.scrollIntoView(diagnosisId);
      }
    }
  }

  private scrollIntoView(diagnosisId: string): void {
    this.diagnosisService.waitForElement(`coder-${diagnosisId}`)
      .then((el: HTMLElement) =>
        el.scrollIntoView({ block: "center" })
      );
  }

  private isBotSource(): boolean {
    return this.selected?.diagnosis?.isBotSource;
  }

  private newProvidedAdded(newProvider: any): void {
    const selectedOption = newProvider.providers.find(a => a.extra.nationalProviderId === newProvider.selectedOption);
    this.providerInput.options = newProvider.providers;
    this.form.get("Diagnosis").get("Provider").setValue(selectedOption?.value.toString());
  }
  private setExemptAttribute(): DynamicEntityAttribute {
    const exemptFromScoringAttribute = RiskHelper.getAttribute(EXEMPT_FROM_SCORING.attributeCode, this.selected.riskEntity);
    const control = this.form.get(this.exemptInput.getMasterKey()) as FormControl;

    let newExemptAttribute;
    if (!ObjectHelper.isEmpty(exemptFromScoringAttribute)) {
      newExemptAttribute = { ...exemptFromScoringAttribute, value: control.value ? "1" : "0" };
    } else {
      newExemptAttribute = {
        chaseId: this.selected.riskEntity.chaseId,
        entityId: this.selected.riskEntity.entityId,
        entityTypeId: EntityType.DIAGNOSIS,
        parentEntityId: this.selected.riskEntity.parentEntityId,
        ...EXEMPT_FROM_SCORING,
        value: control.value ? "1" : "0",
      };
    }
    return newExemptAttribute;
  }
}
