import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LookerRiskDashboardType } from "../../looker-risk-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-client-overread2-dw",
  templateUrl: "./client-overread2-dw.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOverread2DWComponent {
  filters: string[] = ["Projects"];
  dashboardType = LookerRiskDashboardType.CLIENT_OVERREAD2_REPORT_DW;
  hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID];
}
