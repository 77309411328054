import { IAutoMapper } from "../interfaces";

export const mapProviderReceiptResponseModel = (automapper: IAutoMapper): void => {
  automapper
    .createMap("default", "ProviderReceiptResponse")
    .forMember("masterDocumentSourceID", o => o.masterDocumentSourceID)
    .forMember("uploadBatchCode", o => o.uploadBatchCode)
    .forMember("originalDocumentName", o => o.originalDocumentName)
    .forMember("fileSize", o => o.fileSize)
    .forMember("fileCreateDate", o => o.fileCreateDate)
    .forMember("fileCreateTime", o => o.fileCreateTime)
    .forMember("documentUploadStatus", o => o.documentUploadStatus);
};
