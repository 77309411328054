import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { CanDeactivateGuard } from "../../api/url-guards/can-deactivate.guard";
import { AuditPackagesComponent } from "../audit/audit-packages/audit-packages.component";
import { DataLoadComponent } from "../data-load/data-load.component";
import { UploadBulkComponent } from "./approval-center/upload-bulk/upload-bulk.component";
import { UploadDocumentsComponent } from "./approval-center/upload-documents/upload-documents.component";
import { BulkOutreachArchiveComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-archive/bulk-outreach-archive.component";
import { BulkOutreachNewBulkActionComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-new-bulk-action/bulk-outreach-new-bulk-action.component";
import { BulkOutreachPendingActionsComponent } from "./bulk-updates/bulk-outreach/bulk-outreach-pending-actions/bulk-outreach-pending-actions.component";
import { BulkOutreachComponent } from "./bulk-updates/bulk-outreach/bulk-outreach.component";
import { BulkUpdatesComponent } from "./bulk-updates/bulk-updates.component";
import { ChartDownloadComponent } from "./chart-download/chart-download.component";
import { ChaseQueryComponent } from "./chase-query/chase-query.component";
import { DataExtractComponent } from "./data-extract/data-extract.component";
import { GapQueryComponent } from "./gap-query/gap-query.component";
import { InvoiceDownloadComponent } from "./invoice-download/invoice-download.component";
import { IvaSubmissionComponent } from "./iva-submission/iva-submission.component";
import { MyQueriesComponent } from "./my-queries/my-queries.component";
import { NewProjectComponent } from "./new-project/new-project.component";
import { ProjectConfigComponent } from "./project-config/project-config.component";
import { ProjectFilesComponent } from "./project-files/project-files.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { ProjectReportsComponent } from "./project-reports/project-reports.component";

const routes: Routes = [
  { path: "", redirectTo: "admin/projects", pathMatch: "full" },
  { path: "approvalcenter", redirectTo: "/approvalcenter/documents", pathMatch: "full", canActivate: [AuthGuard] },
  { path: "approvalcenter/upload/chase/byid", component: UploadDocumentsComponent, canActivate: [AuthGuard] },
  { path: "approvalcenter/upload/chase/bulk", component: UploadBulkComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: "bulkoutreach", component: BulkOutreachComponent, data: { defaultPath: "/bulkactions/bulkoutreach" }, canActivate: [AuthGuard], children: [
      { path: "", component: BulkOutreachNewBulkActionComponent },
      { path: "archive", component: BulkOutreachArchiveComponent },
      { path: "pendingActions", component: BulkOutreachPendingActionsComponent },
    ] },
  { path: "bulkupdates", component: BulkUpdatesComponent , canActivate: [AuthGuard]},
  { path: "chasequery", component: ChaseQueryComponent , canActivate: [AuthGuard]},
  { path: "chasequery/:ProjectID", component: ChaseQueryComponent , canDeactivate: [CanDeactivateGuard]},
  { path: "dataload", component: DataLoadComponent , canActivate: [AuthGuard]},
  { path: "gapquery", component: GapQueryComponent , canActivate: [AuthGuard]},
  { path: "myqueries", component: MyQueriesComponent , canActivate: [AuthGuard]},
  { path: "dataextract", component: DataExtractComponent , canActivate: [AuthGuard]},
  { path: "home", component: ProjectReportsComponent , canActivate: [AuthGuard]},
  { path: "newproject", component: NewProjectComponent , canActivate: [AuthGuard]},
  { path: "audit-results", component: IvaSubmissionComponent, canActivate: [AuthGuard]},
  { path: "projects/:fileType", component: ProjectListComponent , canActivate: [AuthGuard]}, // TODO : Remove once we test we don't use this url anymore.
  { path: ":projectId", component: ProjectConfigComponent, data: { defaultPath: "/admin/users" }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: ":projectId/auditpackages", component: AuditPackagesComponent , data: { defaultPath: "/clinical/projects/auditpackages" }, canActivate: [AuthGuard]},
  { path: ":projectId/charts", component: ChartDownloadComponent , data: { defaultPath: "/project/projects/charts" }, canActivate: [AuthGuard]},
  { path: ":projectId/dataextract", component: DataExtractComponent , data: { defaultPath: "/project/dataextract" }, canActivate: [AuthGuard]},
  { path: ":projectId/files", component: ProjectFilesComponent , data: { defaultPath: "/project/projects/files" }, canActivate: [AuthGuard]},
  { path: ":projectId/invoices", component: InvoiceDownloadComponent, data: { defaultPath: "/project/projects/invoices" }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule { }
