import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthService } from "../../../auth/auth.service";
import { UserToken } from "../../../auth/user-token.model";
import { LocalService } from "../../../core/storage/local.service";
import { SessionService } from "../../../core/storage/session.service";
import { PopoverComponent } from "../../../shared/panel/popover/popover.component";
import { NumberHelper } from "../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";

@Component({
    selector: "platform-account-menu",
    templateUrl: "./account-menu.component.html",
    styleUrls: ["./account-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent implements OnInit {
    @ViewChild("popover", { static: true }) private popover: PopoverComponent;
    selectedUserId: number;
    backgroundClass = "user-circle ";
    readonly AUTHENTICATION_KEY = "authentication";

    constructor(
        private router: Router,
        private authService: AuthService,
        private session: SessionService,
        private readonly msalService: MsalService,
        private readonly local: LocalService
    ) { }

    ngOnInit() {
        this.getRandomBackgroundColor();
        this.authService.setupUserSessionEvents();
    }


    get user(): UserToken {
        const user = this.session.get(this.AUTHENTICATION_KEY, {});
        return new UserToken(user);
    }

    get username(): string {
        return this.authService.username;
    }

    get activeClass(): string {
        return this.popover.visible ? "username__container--active" : "";
    }

    gotoUser(): void {
        this.router.navigate(["user"]);
        this.popover.hide();
    }

    logout(): void {
        if (this.user?.isSsoUser) {
            this.authService.logOutSsoUser();
            this.authService.clearStorage();
            this.msalService.logout();
        } else {
          this.router.navigate(["login"]).then(response => {
            // get the response from canDeactivate

            if (response) {
              // if response is true, call the logout service
              this.authService.logout();
            } else {
              this.popover.hide();
            }
          });
        }
    }

    get organizationName(): string {
        return this.authService.userOrganizationName;
    }

    get initialUserFirstName(): string {
        const firstName = this.authService.userFirstName;
        if (StringHelper.isAvailable(firstName)) {
            return firstName.charAt(0).toUpperCase();
        }
        return "";
    }

    get initialUserLastName(): string {
        const lastName = this.authService.userLastName;
        if (StringHelper.isAvailable(lastName)) {
            return lastName.charAt(0).toUpperCase();
        }
        return "";
    }

    getRandomBackgroundColor(): void {
        const colors = ["--red-circle", "--teal-circle", "--yellow-circle", "--navy-circle", "--pink-circle"];
        const randomColor = Math.floor(Math.random() * colors.length);
        this.backgroundClass += colors[randomColor];
    }

    getOrgNameTextClass(value: string) {
        let cssClass = "menu__container--user-detail";

        if (NumberHelper.isGreaterThan(value.length, 15, true)) {
            cssClass += " longUserName";
        }

        return cssClass;
    }

    getMenuContainerClass(value: string) {
        let cssClass = "menu__container";

        if (NumberHelper.isGreaterThan(value.length, 15, true)) {
            cssClass += " menuContainerPadding";
        }

        return cssClass;
    }
    getUserNameTextClass(): string {
        const defaultClass = "username__container";
        return NumberHelper.isGreaterThan(this.username.length, 28) ? `${defaultClass}--longLoginName` : `${defaultClass}--loginName`;
    }
}
