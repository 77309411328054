import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_API_URL } from "../../core/environment.tokens";
import { NumberHelper } from "../../utilities/contracts/number-helper";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { AutomapperService } from "../automapper/automapper.service";
import { MessagingService } from "../messaging/messaging.service";
import { SeverityType } from "../messaging/severity-type.enum";

@Injectable({
  providedIn: "root",
})
export class DownloaderService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService,
    private messagingService: MessagingService
  ) { }


  fromPath(path: string) {
    // path: api/file/download?fileType=..&fileId=...
    this.http.get(
      `${this.baseApiUrl}${path}`,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  fromRawUrl(url: string) {
    // url: https://dev.api.healthdatavision.com/api/file/download?fileType=..&fileId=...
     this.http.get(
      url,
      { responseType: "blob" as "json", observe: "response" as "body" }).subscribe(
        (response: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(response.body);
          const disposition = response.headers.get("content-disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let dispositionFileName = "";
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              dispositionFileName = matches[1].replace(/['"]/g, "");
            }
            downloadLink.download = dispositionFileName;
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      );
  }

  transformApiResponseToCSVFile(url: string, from: string, to: string,
                                fileName: string, headers: any): void {
     this.downloadDocumentMetaData(url, from, to)
      .subscribe({
        next: data => {
          this.downloadCSVFile(data, fileName, headers);
          this.messagingService.showToast(`File downloaded successfully.`, SeverityType.SUCCESS);
        },
        error: error => {
          this.messagingService.showToast(`File download failed.`, SeverityType.ERROR);
        },
      });
  }

  private downloadCSVFile(data: any[], fileName: string, headers: any): void {
    const replacer = (_, value) => StringHelper.isAvailable(value) || NumberHelper.isAvailable(value) ? value : "";
    const csv = data.map(row => headers.map(header => JSON.stringify(row[header.column], replacer)).join(","));
    csv.unshift(headers.map(h => h.display).join(","));
    const csvArray = csv.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    a.remove();
  }

  private downloadDocumentMetaData(url: string, from: string, to: string): Observable<any[]> {
      return this.http.get(url)
      .pipe(
      map(this.automapper.curryMany(from, to))
      );
  }
}
