<div class="chase-timeline-container" [ngClass]="{'chase-timeline-container__first': index === 0}">
  <div class="iconContainer">
    <app-icon-stack [iconName]="item.type" iconBase="circle"></app-icon-stack>
  </div>
  <div class="container">
    <div>{{ item.title }}
      <div class="download-receipt-container" *ngIf="item.title.includes('Provider Gateway')">
      <a class="download-receipt-icon" (click)="downloadReceipt(item.note)"><app-icon iconName="download" class="downloadIcon" ></app-icon><span class="download-receipt-text">| Download Receipt</span></a>
      </div>
  </div>
    <div *ngIf="showDetails">
      <div>{{ item.details }}</div>
    </div>
    <div>{{getNotes(item.note)}} <span *ngIf="hasUrl"><a href="{{item.urlPath}}">{{item.urlText}}</a></span></div>
    <div>{{getFilterNotes(item.note)}}</div>
    <div>{{getAdditionalNote(item.note)}}</div>
    <div *ngIf="isPursuitApproved(item?.title)">by {{ item.username }}</div>
    <div>{{ item.timestamp }}</div>
  </div>
</div>
