import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "../../auth/auth.service";
import { HttpStatusCode } from "../meta/http-status-code.enum";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: Only use the token. "this.authService.user.token"
    // TODO: Use Bearer scheme (as recomended by jwt and community) `Bearer ${this.authService.user.token}`
    // NOTE: Token Verification is a form of Authentication; not authorization.

    let requestToUse = req;
    const authToken = JSON.stringify(this.authService.user);
    if (!req.url.includes("s3.amazonaws.com")
      || (req.url.includes("s3.amazonaws.com") && req.url.includes("api/file/download"))
      || !req.url.includes("communicationservice")) {
      requestToUse = req.clone({ headers: req.headers.set("Authorization", authToken) });
    }
    if (req.url.includes("communicationservice")) {
      requestToUse = req.clone({
        responseType: "text" });
    }

    if (req.url.includes("s3.amazonaws.com") && !req.url.includes("api/file/download")) {
      return next.handle(req).pipe(
        tap(
          event => { },
          error => { }
        )
      );
    } else {
      return next.handle(requestToUse).pipe(
        tap(
          event => { },
          error => {
            if (error instanceof HttpErrorResponse) {
              switch (error.status) {
                case HttpStatusCode.InvalidToken:
                  this.authService.logout();
                  break;
                default:
                  // do nothing;
                  break;
              }
            }
          }
        )
      );
    }
  }
}
