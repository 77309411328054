import { InjectionToken, Type } from "@angular/core";
import { UserToken } from "../../auth/user-token.model";
import { DischargeCalculatedDates } from "../../dynamic-forms/form-groups/discharge-group/discharge-calculated-dates.model";
import { DatesBetween } from "../../dynamic-forms/form-groups/ppc/dates-between/dates-between.model";
import { PpcDates } from "../../dynamic-forms/form-groups/ppc/ppc-dates.model";
import { SelectableInput } from "../../dynamic-forms/inputs/selectable-input.model";
import { ChaseItem } from "../../platform/api/chase-search/chase-search-result-item";
import { DocumentUploadResponse } from "../../platform/api/document-upload/document-upload-response.model";
import { DocumentValidateResponse } from "../../platform/api/document-upload/document-validate-response.model";
import { DynamicEntityAttribute } from "../../platform/api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../platform/api/member-validation/dynamic-entity.model";
import { EntityError } from "../../platform/api/member-validation/entity-error.model";
import { MemberValidation } from "../../platform/api/member-validation/member-validation.model";
import { ProviderItem } from "../../platform/api/provider/provider-search-result-item";
import { VendorExclusion } from "../../platform/api/vendor/vendorExclusion.model";
import { WorkflowSubmitResponse } from "../../platform/api/workflow/workflow-submit-response.model";
import { AnalyticsItem } from "../../platform/modules/analytics/models/analytics-item.model";
import { DashboardCount } from "../../platform/modules/analytics/models/dashboard-count.model";
import { ApprovalCenterItem } from "../../platform/modules/approval-center/approval-center-item.model";
import { ChartLakeReviewModel } from "../../platform/modules/chart-lake/chart-lake-review/chart-lake-review.model";
import { ClinicalMeasureListItem } from "../../platform/modules/clinical/clinical-page/clinical-measure-list-item.model";
import { ClinicalQueueListItem } from "../../platform/modules/clinical/clinical-queue/clinical-queue-list-item.model";
import { ClinicalChaseListItem } from "../../platform/modules/dashboard/clinical/clinical-chase-list-item.model";
import { DocumentReviewerChaseListItem } from "../../platform/modules/dashboard/document-reviewer/document-reviewer-chase-list-item.model";
import { AddressListItem } from "../../platform/modules/dashboard/retrieval/address-list-item.model";
import { DocumentData } from "../../platform/modules/event-lake/document-data.model";
import { OcrResult } from "../../platform/modules/event-lake/ocr-results/ocr-result.model";
import { OcrPageSection } from "../../platform/modules/event-lake/ocr-section/ocr-page-section.model";
import { OcrPageWorkflowProgress } from "../../platform/modules/event-lake/workflow-progress/ocr-page-workflow.model";
import { PendsAttemptHistory } from "../../platform/modules/internal-pends/internal-pends-detail/internal-pends-detail-attempt-history/pends-attempt-history.model";
import { PendDocumentItem } from "../../platform/modules/internal-pends/internal-pends-detail/internal-pends-detail-documents/pend-document-list-item.model";
import { ProviderPacketResponseItem } from "../../platform/modules/internal-pends/internal-pends-detail/internal-pends-detail-info/provider-packet-response-packet-item.model";
import { UpdatePend } from "../../platform/modules/internal-pends/internal-pends-detail/internal-pends-update-pend/update-pend.model";
import { PendListItem } from "../../platform/modules/internal-pends/internal-pends-listing/pend-list-item.model";
import { PendTimelineItem } from "../../platform/modules/internal-pends/pend-timeline-item.model";
import { DocumentQueueResponse } from "../../platform/modules/invoice/invoices/documentqueue-response.model";
import { InvoiceChaseValidation } from "../../platform/modules/invoice/invoices/model/invoice-chase-validation.model";
import { InvoiceDetail } from "../../platform/modules/invoice/invoices/model/invoice-detail.model";
import { VendorDetail } from "../../platform/modules/invoice/vendor-detail/vendor-detail.model";
import { JobQueueItem } from "../../platform/modules/jobsqueue/jobqueue-item.model";
import { JobQueueUnRead } from "../../platform/modules/jobsqueue/jobqueue-unread.model";
import { ArchiveChaseDetail } from "../../platform/modules/member/archive-chase-detail/archive-chase-detail.model";
import { ChartFilter } from "../../platform/modules/member/chase-detail-v2/chart-filter.model";
import { ChartFilters } from "../../platform/modules/member/chase-detail-v2/chart-filters.model";
import { EveIcd } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/diagnosis/eve/eve-icd.model";
import { ClinicalTerm } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-encounter/eve-clinical-terms/clinical-term.model";
import { EncounterSummary } from "../../platform/modules/member/chase-detail-v2/chase-detail-v2-chart/risk/risk-submit/summary/summary.model";
import { ChaseDetailAuditLog } from "../../platform/modules/member/chase-detail/chase-detail-audit-log/chase-detail-audit-log.model";
import { ExlcusionAndContra } from "../../platform/modules/member/chase-detail/chase-detail-chart/exclusion-and-contra/exclusion-and-contra.model";
import { NonCompliantInfo } from "../../platform/modules/member/chase-detail/chase-detail-chart/exclusion-and-contra/non-compliant-info.model";
import { CatalyticLocations } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-locations.model";
import { CatalyticNumerator } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-numerator.model";
import { CatalyticResult } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-result.model";
import { CatalyticSupportingLocation } from "../../platform/modules/member/chase-detail/chase-detail-chart/nlp-hedis/catalytic-supporting-location.model";
import { OverreadFeedbackDetail } from "../../platform/modules/member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-detail.model";
import { OverreadFeedbackQueryItem } from "../../platform/modules/member/chase-detail/chase-detail-chart/overread-feedback/overread-feedback-query-item.model";
import { Diagnosis } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/diagnosis/diagnosis.model";
import { DiseaseDetail } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/diagnosis/disease-detail.model";
import { Encounter } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskEntity } from "../../platform/modules/member/chase-detail/chase-detail-chart/risk/risk-entity.model";
import { ChaseDetailHealthCard } from "../../platform/modules/member/chase-detail/chase-detail-health-card/chase-detail-health-card.model";
import { HealthCardIcd } from "../../platform/modules/member/chase-detail/chase-detail-health-card/health-card-icd";
import { ChaseDetailInfoPursuitItemModel } from "../../platform/modules/member/chase-detail/chase-detail-info/chase-detail-info-pursuits/chase-detail-info-pursuit-item/chase-detail-info-pursuit-item-model";
import { GapCompliance } from "../../platform/modules/member/chase-detail/chase-detail-info/gap-compliance.model";
import { ChaseDetail } from "../../platform/modules/member/chase-detail/chase-detail.model";
import { ChaseDocumentPage } from "../../platform/modules/member/chase-document-page.model";
import { MemberSearchResult } from "../../platform/modules/member/create-new-chase/create-new-chase-member/member-search-result.model";
import { MemberDetail } from "../../platform/modules/member/member-detail/member-detail.model";
import { MemberTimelineItem } from "../../platform/modules/member/member-timeline-item.model";
import { MemberListItem } from "../../platform/modules/member/member/member-listing/member-list-item.model";
import { DocumentApprovalSearchResponse } from "../../platform/modules/project/approval-center/document-approval-search-results";
import { AddressBulkUpdate } from "../../platform/modules/project/bulk-updates/address-bulk-update.model";
import { BulkChaseValidation } from "../../platform/modules/project/bulk-updates/bulk-chase-validation.model";
import { BulkOutReachQueryResult } from "../../platform/modules/project/bulk-updates/bulk-outreach/bulk-outreach-new-bulk-action/bulk-outreach-query-result.model";
import { BulkUpdate } from "../../platform/modules/project/bulk-updates/bulk-update.model";
import { ReportingStatusModel } from "../../platform/modules/project/chase-query/Reporting-status.model";
import { WorkflowStatusModel } from "../../platform/modules/project/chase-query/workflow-status.model";
import { HccModel } from "../../platform/modules/project/hcc.model";
import { AuthorizationLetterAttribute } from "../../platform/modules/project/project-config/models/authorization-letter-attribute.model";
import { FileDetail } from "../../platform/modules/project/project-config/models/file-detail.model";
import { MappingDocument } from "../../platform/modules/project/project-config/models/mapping-document.model";
import { Measures } from "../../platform/modules/project/project-config/models/measures.model";
import { ProjectAttribute } from "../../platform/modules/project/project-config/models/project-attribute.model";
import { ProjectFileResponse } from "../../platform/modules/project/project-files/project-file/project-file-response.model";
import { ProjectFile } from "../../platform/modules/project/project-files/project-file/project-file.model";
import { ProjectTemplateModel } from "../../platform/modules/project/project-template.model";
import { AddressDetailInfoEdit } from "../../platform/modules/retrieval/address-detail/address-detail-info/address-detail-info-edit/address-detail-info-edit.model";
import { AddressDetail } from "../../platform/modules/retrieval/address-detail/address-detail.model";
import { AddressSearchResult } from "../../platform/modules/retrieval/address-search/address-search-result.model";
import { AddressesQueueListItem } from "../../platform/modules/retrieval/addresses-queue/addresses-queue-list-item.model";
import { Appointment } from "../../platform/modules/retrieval/appointment/appointment.model";
import { EmrEditModel } from "../../platform/modules/retrieval/emr/emr-detail/emr-detail-edit/emr-edit-model";
import { FtEditModel } from "../../platform/modules/retrieval/field-tech/ft-detail/ft-detail-edit/ft-edit-model";
import { AddressEditModel } from "../../platform/modules/retrieval/psr/address-detail/address-detail-edit/address-edit-model";
import { MemberWithChases } from "../../platform/modules/retrieval/psr/address-detail/address-detail-info/member-with-chases.model";
import { ChasePageHistory } from "../../platform/modules/retrieval/retreival-document-review/chase-page-history.model";
import { DocumentPage } from "../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { DocumentPages } from "../../platform/modules/retrieval/retreival-document-review/document-pages.models";
import { RetrievalCallRecordDetail } from "../../platform/modules/retrieval/retrieval-detail-call-record";
import { RetrievalContactHistory } from "../../platform/modules/retrieval/retrieval-detail-contact-history.model";
import { RetrievalTimelineItem } from "../../platform/modules/retrieval/retrieval-timeline-item.model";
import { BulkUserResponse } from "../../platform/modules/service-org-admin/bulk-user-upload/bulk-user-response.model";
import { CustomPendCode } from "../../platform/modules/service-org-admin/service-org-config/model/custom-pend-code.model";
import { DocumentRouting } from "../../platform/modules/service-org-admin/service-org-config/model/document-routing.model";
import { PendCodeCount } from "../../platform/modules/service-org-admin/service-org-config/model/Pend-Code-Count.model";
import { PendCodeList } from "../../platform/modules/service-org-admin/service-org-config/model/Pend-Code-List.model";
import { ServiceOrgAttribute } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-attribute.model";
import { ServiceOrgConfigModel } from "../../platform/modules/service-org-admin/service-org-config/model/service-org-config.model";
import { SsoConfiguration } from "../../platform/modules/service-org-admin/service-org-config/model/sso-configuration.model";
import { Tags } from "../../platform/modules/service-org-admin/service-org-config/model/Tags.model";
import { TotalTagCount } from "../../platform/modules/service-org-admin/service-org-config/model/total-tag-count.model";
import { ClientNodeModel } from "../../platform/modules/service-org-admin/user-admin/client-node.model";
import { ProjectNodeModel } from "../../platform/modules/service-org-admin/user-admin/project-node.model";
import { ServiceOrgNodeModel } from "../../platform/modules/service-org-admin/user-admin/service-org-node.model";
import { AlertNotification } from "../../platform/notification/alert-notification.model";
import { NotificationItem } from "../../platform/notification/notification-item.model";
import { Annotation } from "../../platform/widget/annotations/annotation.model";
import { UserGroupItem } from "../../platform/widget/user-chat/user-chat.model";
import { ProviderPacketModel } from "../../provider/chart-upload/email-verification/provider-packet.model";
import { ProviderCustomDetail } from "../../provider/provider-custom-detail.model";
import { Provider } from "../../provider/provider.model";
import { ButtonAction } from "../../shared/button/button-action.model";
import { CommentItem } from "../../shared/comments/comment-item/comment-item";
import { DocumentListItem } from "../../shared/document/chase-document-list/document-list-item.model";
import { BoundingBox } from "../../shared/document/document-page-viewer/bounding-box.model";
import { DocumentOcrMatch } from "../../shared/document/document-page-viewer/document-ocr-match.model";
import { DocumentPageNlpMatches } from "../../shared/document/document-page-viewer/document-page-nlp/document-page-nlp-matches.model";
import { NlpWord } from "../../shared/document/document-page-viewer/document-page-nlp/nlp-word.model";
import { DocumentPageOcrMatches } from "../../shared/document/document-page-viewer/document-page-ocr-matches.model";
import { OcrMatch } from "../../shared/document/document-page-viewer/ocr-match.model";
import { OcrWord } from "../../shared/document/document-page-viewer/ocr-word.model";
import { DocumentRequest } from "../../shared/document/document-request.model";
import { GridView } from "../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { SaveQuery } from "../../shared/grid/save-query/save-query.model";
import { DataSet } from "../../shared/kpi/model/data-set.model";
import { ListItem } from "../../shared/list/list-item";
import { ProviderPacketLogoResponseModel } from "../../shared/logo/provider-packet-logo-response.model";
import { ServiceOrgLogoResponseModel } from "../../shared/logo/service-org-logo-response.model";
import { MemberCentricChase } from "../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { MenuItem } from "../../shared/menu/menu-item.model";
import { DropdownListItem } from "../../shared/models/dropdown-list-item";
import { LookupModel } from "../../shared/models/lookup-model";
import { MasterProvider } from "../../shared/national-provider-grid/national-provider.model";
import { ChasePendItem } from "../../shared/pend/chase-pend-item.model";
import { TagAddressNotes } from "../../shared/tags/model/tag-address-notes.model";
import { ProviderReceiptResponse } from "../../shared/timeline/timeline-download-receipt.model";
import { TimelineItem } from "../../shared/timeline/timeline-item.model";
import { Dictionary } from "../../utilities/dictionary";
import { ClientOrgModel } from "../user/client-org-model";
import { RoleModel } from "../user/role-model";
import { UserAttributeDisplayModel } from "../user/user-attribute-display-model";
import { UserAttributeModel } from "../user/user-attribute-model";
import { UserModel } from "../user/user-model";
import { UserRoleModel } from "../user/user-role-model";


export const STRING_TO_CLASS_DICTIONARY = new InjectionToken(
  "Without a mapping from a class name to a constructor, the application will break due to minification.",
  {
    providedIn: "root",
    factory: () => {
      const dictionary = new Dictionary<Type<any>>();

      dictionary.add("AddressBulkUpdate", AddressBulkUpdate);
      dictionary.add("AddressDetail", AddressDetail);
      dictionary.add("AddressDetailInfoEdit", AddressDetailInfoEdit);
      dictionary.add("AddressEditModel", AddressEditModel);
      dictionary.add("AddressesQueueListItem", AddressesQueueListItem);
      dictionary.add("AddressListItem", AddressListItem);
      dictionary.add("AddressSearchResult", AddressSearchResult);
      dictionary.add("AlertNotification", AlertNotification);
      dictionary.add("AnalyticsItem", AnalyticsItem);
      dictionary.add("Annotation", Annotation);
      dictionary.add("Appointment", Appointment);
      dictionary.add("ApprovalCenterItem", ApprovalCenterItem);
      dictionary.add("ArchiveChaseDetail", ArchiveChaseDetail);
      dictionary.add("AuthorizationLetterAttribute", AuthorizationLetterAttribute);
      dictionary.add("BoundingBox", BoundingBox);
      dictionary.add("BulkChaseValidation", BulkChaseValidation);
      dictionary.add("BulkOutReachQueryResult", BulkOutReachQueryResult);
      dictionary.add("BulkUpdate", BulkUpdate);
      dictionary.add("BulkUserResponse", BulkUserResponse);
      dictionary.add("ButtonAction", ButtonAction);
      dictionary.add("CatalyticLocations", CatalyticLocations);
      dictionary.add("CatalyticNumerator", CatalyticNumerator);
      dictionary.add("CatalyticResult", CatalyticResult);
      dictionary.add("CatalyticSupportingLocation", CatalyticSupportingLocation);
      dictionary.add("ChartFilter", ChartFilter);
      dictionary.add("ChartFilters", ChartFilters);
      dictionary.add("ChartLakeReviewModel", ChartLakeReviewModel);
      dictionary.add("ChaseDetail", ChaseDetail);
      dictionary.add("ChaseDetailAuditLog", ChaseDetailAuditLog);
      dictionary.add("ChaseDetailHealthCard", ChaseDetailHealthCard);
      dictionary.add("ChaseDetailInfoPursuitItem", ChaseDetailInfoPursuitItemModel);
      dictionary.add("ChaseDocumentPage", ChaseDocumentPage);
      dictionary.add("ChaseItem", ChaseItem);
      dictionary.add("ChasePageHistory", ChasePageHistory);
      dictionary.add("ChasePendItem", ChasePendItem);
      dictionary.add("ClientNodeModel", ClientNodeModel);
      dictionary.add("ClientOrgModel", ClientOrgModel);
      dictionary.add("ClinicalChaseListItem", ClinicalChaseListItem);
      dictionary.add("ClinicalMeasureListItem", ClinicalMeasureListItem);
      dictionary.add("ClinicalQueueListItem", ClinicalQueueListItem);
      dictionary.add("ClinicalTerm", ClinicalTerm);
      dictionary.add("CommentItem", CommentItem);
      dictionary.add("CustomPendCode", CustomPendCode);
      dictionary.add("DashboardCount", DashboardCount);
      dictionary.add("DataSet", DataSet);
      dictionary.add("DatesBetween", DatesBetween);
      dictionary.add("Diagnosis", Diagnosis);
      dictionary.add("DischargeCalculatedDates", DischargeCalculatedDates);
      dictionary.add("DiseaseDetail", DiseaseDetail);
      dictionary.add("DocumentApprovalSearchResponse", DocumentApprovalSearchResponse);
      dictionary.add("DocumentData", DocumentData);
      dictionary.add("DocumentListItem", DocumentListItem);
      dictionary.add("DocumentOcrMatch", DocumentOcrMatch);
      dictionary.add("DocumentPage", DocumentPage);
      dictionary.add("DocumentPages", DocumentPages);
      dictionary.add("DocumentPageNlpMatches", DocumentPageNlpMatches);
      dictionary.add("DocumentPageOcrMatches", DocumentPageOcrMatches);
      dictionary.add("DocumentQueueResponse", DocumentQueueResponse);
      dictionary.add("DocumentRequest", DocumentRequest);
      dictionary.add("DocumentReviewerChaseListItem", DocumentReviewerChaseListItem);
      dictionary.add("DocumentRouting", DocumentRouting);
      dictionary.add("DocumentUploadResponse", DocumentUploadResponse);
      dictionary.add("DocumentValidateResponse", DocumentValidateResponse);
      dictionary.add("DropdownListItem", DropdownListItem);
      dictionary.add("DynamicEntity", DynamicEntity);
      dictionary.add("DynamicEntityAttribute", DynamicEntityAttribute);
      dictionary.add("EmrEditModel", EmrEditModel);
      dictionary.add("Encounter", Encounter);
      dictionary.add("EncounterSummary", EncounterSummary);
      dictionary.add("EntityError", EntityError);
      dictionary.add("EveIcd", EveIcd);
      dictionary.add("ExlcusionAndContra", ExlcusionAndContra);
      dictionary.add("FileDetail", FileDetail);
      dictionary.add("FtEditModel", FtEditModel);
      dictionary.add("GapCompliance", GapCompliance);
      dictionary.add("GridView", GridView);
      dictionary.add("GridViewsState", GridViewsState);
      dictionary.add("HccModel", HccModel);
      dictionary.add("HealthCardIcd", HealthCardIcd);
      dictionary.add("InvoiceChaseValidation", InvoiceChaseValidation);
      dictionary.add("InvoiceDetail", InvoiceDetail);
      dictionary.add("JobQueueItem", JobQueueItem);
      dictionary.add("JobQueueUnRead", JobQueueUnRead);
      dictionary.add("ListItem", ListItem);
      dictionary.add("LookupModel", LookupModel);
      dictionary.add("MappingDocument", MappingDocument);
      dictionary.add("MasterProvider", MasterProvider);
      dictionary.add("Measures", Measures);
      dictionary.add("MemberCentricChase", MemberCentricChase);
      dictionary.add("MemberDetail", MemberDetail);
      dictionary.add("MemberListItem", MemberListItem);
      dictionary.add("MemberSearchResult", MemberSearchResult);
      dictionary.add("MemberTimelineItem", MemberTimelineItem);
      dictionary.add("MemberValidation", MemberValidation);
      dictionary.add("MemberWithChases", MemberWithChases);
      dictionary.add("MenuItem", MenuItem);
      dictionary.add("NlpWord", NlpWord);
      dictionary.add("NonCompliantInfo", NonCompliantInfo);
      dictionary.add("NotificationItem", NotificationItem);
      dictionary.add("OcrMatch", OcrMatch);
      dictionary.add("OcrPageSection", OcrPageSection);
      dictionary.add("OcrPageWorkflowProgress", OcrPageWorkflowProgress);
      dictionary.add("OcrResult", OcrResult);
      dictionary.add("OcrWord", OcrWord);
      dictionary.add("OverreadFeedbackDetail", OverreadFeedbackDetail);
      dictionary.add("OverreadFeedbackQueryItem", OverreadFeedbackQueryItem);
      dictionary.add("PendCodeCount", PendCodeCount);
      dictionary.add("PendCodeList", PendCodeList);
      dictionary.add("PendDocumentItem", PendDocumentItem);
      dictionary.add("PendListItem", PendListItem);
      dictionary.add("PendsAttemptHistory", PendsAttemptHistory);
      dictionary.add("PendTimelineItem", PendTimelineItem);
      dictionary.add("PpcDates", PpcDates);
      dictionary.add("ProjectAttribute", ProjectAttribute);
      dictionary.add("ProjectFile", ProjectFile);
      dictionary.add("ProjectNodeModel", ProjectNodeModel);
      dictionary.add("ProjectFileResponse", ProjectFileResponse);
      dictionary.add("ProjectTemplateModel", ProjectTemplateModel);
      dictionary.add("Provider", Provider);
      dictionary.add("ProviderCustomDetail", ProviderCustomDetail);
      dictionary.add("ProviderItem", ProviderItem);
      dictionary.add("ProviderPacketLogoResponseModel", ProviderPacketLogoResponseModel);
      dictionary.add("ProviderPacketModel", ProviderPacketModel);
      dictionary.add("ProviderPacketResponseItem", ProviderPacketResponseItem);
      dictionary.add("ProviderReceiptResponse", ProviderReceiptResponse);
      dictionary.add("ReportingStatusModel", ReportingStatusModel);
      dictionary.add("RetrievalCallRecordDetail", RetrievalCallRecordDetail);
      dictionary.add("RetrievalContactHistory", RetrievalContactHistory);
      dictionary.add("RetrievalTimelineItem", RetrievalTimelineItem);
      dictionary.add("RiskEntity", RiskEntity);
      dictionary.add("RoleModel", RoleModel);
      dictionary.add("SaveQuery", SaveQuery);
      dictionary.add("SelectableInput", SelectableInput);
      dictionary.add("ServiceOrgAttribute", ServiceOrgAttribute);
      dictionary.add("ServiceOrgConfigModel", ServiceOrgConfigModel);
      dictionary.add("ServiceOrgLogoResponseModel", ServiceOrgLogoResponseModel);
      dictionary.add("ServiceOrgNodeModel", ServiceOrgNodeModel);
      dictionary.add("SsoConfiguration", SsoConfiguration);
      dictionary.add("TagAddressNotes", TagAddressNotes);
      dictionary.add("Tags", Tags);
      dictionary.add("TimelineItem", TimelineItem);
      dictionary.add("TotalTagCount", TotalTagCount);
      dictionary.add("UpdatePendItem", UpdatePend);
      dictionary.add("UserAttributeDisplayModel", UserAttributeDisplayModel);
      dictionary.add("UserAttributeModel", UserAttributeModel);
      dictionary.add("UserGroupItem", UserGroupItem);
      dictionary.add("UserModel", UserModel);
      dictionary.add("UserRoleModel", UserRoleModel);
      dictionary.add("UserToken", UserToken);
      dictionary.add("VendorDetail", VendorDetail);
      dictionary.add("VendorExclusion", VendorExclusion);
      dictionary.add("WorkflowStatusModel", WorkflowStatusModel);
      dictionary.add("WorkflowSubmitResponse", WorkflowSubmitResponse);


      return dictionary;
    },
  }
);
