<div class="data-extract__container">
  <div class="header bold">DATA EXTRACT</div>
  <div class="ui-g-12 ui-md-12 ui-lg-12">
    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <form-factory [formGroup]="form" [model]="projectInput" (onChange)="onProjectChange()"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <form-factory [formGroup]="form" [model]="delimiterInput"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-3 ui-lg-3 member-search__existing-member">
      <form-factory [formGroup]="form" [model]="includeHeaderInput"></form-factory>
    </div>
    <div class="ui-g-12 ui-md-3 ui-lg-3 button-div">
      <app-button class="header-button" text="GENERATE EXTRACT" (onClick)="showHideConfirmationModal(true)" [disabled]="!isProjectSelected"></app-button>
    </div>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12">
    <div class="data-extract-text">Extracts are accessible for 90 days.</div>
    <app-basic-grid [configuration]="gridConfigurationModel"
                    [data]="projectFiles"
                    [actionTemplate]="actionTemplate"
                    [actions]="actions">

      <ng-template #actionTemplate let-i="index" let-rowData let-localActionPopover="actionPopover">
        <div class="action-template-container">
          <app-button *ngFor="let action of actions; trackBy: trackByIndex"
                  [text]="action.name"
                  (onClick)="action.action(rowData); localActionPopover.hide();" [disabled]="rowData.isDisabled"></app-button>
        </div>
      </ng-template>

    </app-basic-grid>

  </div>
  <app-modal [(visible)]="isFileDelete" header="DELETE REQUEST">
    <div class="text-center">
      Are you sure you want to delete this file?
    </div>
    <footer>
      <app-button text="Delete" (onClick)="onDeleteFile()"></app-button>
    </footer>
  </app-modal>
  <app-confirmation-modal [(visible)]="isConfirmationModalVisible"
                          header="Confirmation"
                          (onYes)="processRequest()"
                          (onCancel)="showHideConfirmationModal(false)"
                          class="data-extract__confirmation-modal">
    You are about to generate an extract for {{projectName}}. Please confirm if you would like to proceed.
  </app-confirmation-modal>

</div>
<app-result-modal 
  class="processing-modal" 
  [(visible)]="isProcessingVisible" 
  header="DATA EXTRACT IN PROGRESS... YOU MAY LEAVE THIS PAGE BY PRESSING THE ESC BUTTON. YOU WILL BE NOTIFIED WHEN YOUR EXTRACT IS COMPLETE."
  [allowClose]="true">
  <div class="processing-modal__icon-spinner">
    <app-icon iconName="spinner" spin=true></app-icon>
  </div>
</app-result-modal>
